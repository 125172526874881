import { collection, doc, getFirestore, orderBy, query } from "firebase/firestore";
import * as React from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { app } from "../firebase";
const FujiPoc = () => {

    const [value, loading, error] = useDocument(doc(getFirestore(app), 'fuji', 'device1'), {
        snapshotListenOptions: { includeMetadataChanges: true }
    });

    const telementryQuery = query(collection(getFirestore(app), 'fuji/device1/telemetry'),orderBy('updatedAt','desc'));
    const [valueCollection, loadingCollection, errorCollection] = useCollection(telementryQuery, {
        snapshotListenOptions: { includeMetadataChanges: true }
    });
    return <div>
        <p>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Document: Loading...</span>}
            {/* {value && <span>Document: {JSON.stringify(value.data())}</span>} */}
        </p>
        {value &&
            <div className="flex max-w-1 flex-col gap-5 mx-auto my-10" style={{width:'80%'}} >
                <div className="stats shadow">

                    <div className="stat place-items-center">
                        <div className="stat-title">Temprature</div>
                        <div className="stat-value">{value.data().data1}</div>
                        <div className="stat-desc">{value.data().updatedAt.toDate().toLocaleString()}</div>
                    </div>

                    <div className="stat place-items-center">
                        <div className="stat-title">Heat</div>
                        <div className="stat-value text-secondary">{value.data().data2}</div>
                        <div className="stat-desc text-secondary">↗︎ 40 (2%)</div>
                    </div>

                    <div className="stat place-items-center">
                        <div className="stat-title">Data 3</div>
                        <div className="stat-value">{value.data().data3}</div>
                        <div className="stat-desc">↘︎ 90 (14%)</div>
                    </div>

                </div>
                <div className="stats shadow">

                    <div className="stat place-items-center">
                        <div className="stat-title">Data 4</div>
                        <div className="stat-value">{value.data().data4}</div>
                        <div className="stat-desc">{value.data().updatedAt.toDate().toDateString()}</div>
                    </div>

                    <div className="stat place-items-center">
                        <div className="stat-title">Data 5</div>
                        <div className="stat-value text-secondary">{value.data().data5}</div>
                        <div className="stat-desc text-secondary">↗︎ 40 (2%)</div>
                    </div>

                    <div className="stat place-items-center">
                        <div className="stat-title">Data 6</div>
                        <div className="stat-value">{value.data().data6}</div>
                        <div className="stat-desc">↘︎ 90 (14%)</div>
                    </div>

                </div>
            </div>
        }

        {valueCollection && <div className="overflow-x-auto mx-auto" style={{width:'80%'}}>
            <table className="table w-full">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Data 1</th>
                        <th>Data 2</th>
                        <th>Data 3</th>
                        <th>Data 4</th>
                        <th>Data 5</th>
                        <th>Data 6</th>
                    </tr>
                </thead>
                <tbody>
                    {valueCollection.docs.map(doc=>{
                        return <tr className="hover" key={doc.id}>
                            <th>{doc.data().updatedAt.toDate().toLocaleString()}</th>
                            <th>{doc.data().data1}</th>
                            <th>{doc.data().data2}</th>
                            <th>{doc.data().data3}</th>
                            <th>{doc.data().data4}</th>
                            <th>{doc.data().data5}</th>
                            <th>{doc.data().data6}</th>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>}
    </div>
}

export default FujiPoc

export const Head = () => <title>FourLink- Fuji POC</title>